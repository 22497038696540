const cards = (role) => {
  switch (role) {
    case "wa":
      return [
        {
          category: "Data",
          title: "dataLayer",
          stars: 4.5,
          li1: "Data strategie",
          li2: "dataLayer architectuur",
          li3: "Clickstream collection",
          li4: "Implementatie",
        },
        {
          category: "Data",
          title: "Google Tag Manager",
          stars: 4.5,
          li1: "Tagging voor alle platforms",
          li2: "Templates",
          li3: "Custom JavaScript",
          li4: "Container structuur",
        },
        {
          category: "Analytics",
          title: "Web Analytics",
          stars: 4.5,
          li1: "Google Analytics",
          li2: "Divolte",
          li3: "Hotjar",
          li4: "Google BigQuery",
        },
        {
          category: "Growth",
          title: "Experimenten",
          stars: 4.5,
          li1: "GROWS framework",
          li2: "A/B Testing",
          li3: "Google Optimize",
          li4: "Server side testing",
        },
        {
          category: "Growth",
          title: "Marketing Automation",
          stars: 4.5,
          li1: "Personalisatie",
          li2: "Salesforce Marketing Cloud",
          li3: "Google Marketing Platform",
          li4: "Feed based marketing",
        },
        {
          category: "Growth",
          title: "Online Advertising",
          stars: 4,
          li1: "Google Ads",
          li2: "Facebook Ads",
          li3: "Google Marketing Platform",
          li4: "Retargeting (Criteo/GMP)",
        },
        {
          category: "Business",
          title: "Soft Skills",
          stars: 3.5,
          li1: "Growth strategie",
          li2: "Stakeholder management",
          li3: "Agile werken",
          li4: "Training geven",
        },
        {
          category: "Development",
          title: "Front-end",
          stars: 3.5,
          li1: "JavaScript",
          li2: "HTML/CSS",
          li3: "React",
          li4: "Git",
        },
        {
          category: "Development",
          title: "Back-end",
          stars: 2,
          li1: "Python",
          li2: "Node.js",
          li3: "Google Cloud Functions",
          li4: "API's",
        },
      ];
    default:
      return [
        {
          category: "Data",
          title: "Data Analytics",
          stars: 4.5,
          intro:
            "Voor gestructureerde groei is het essentieel dat je naar juiste data kijkt. Om dit op orde te krijgen kan ik helpen met:",
          list: [
            "Tracking plan",
            "Event tracking",
            "Web analytics",
            "Product analytics",
            "Tag management",
            "DataLayer inrichten",
          ],
        },
        {
          category: "Product",
          title: "Experimenten",
          stars: 5,
          intro:
            "Door snel te experimenteren volgens een bewezen proces weet je binnen no-time wat goed werkt voor jouw product.",
          list: [
            "Ideeën prioritiseren",
            "Experimenten definiëren",
            "A/B testen",
            "Resultaten analyseren",
            "Implementatie van winnende ideeën",
          ],
        },
        {
          category: "Code",
          title: "Development",
          stars: 4,
          intro:
            "Ik bouw experimenten, MVP's, demo's en robuuste product analytics met moderne technologieën. Fullstack, met de focus op frontend.",
          list: [
            "Javascript, Typescript",
            "Next.js, React",
            "HTML & CSS",
            "Python",
            "Serverless",
            "Git",
          ],
        },
      ];
  }
};

export default cards;
