import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import CakeIcon from "@material-ui/icons/Cake";
import Grid from "@material-ui/core/Grid";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import GitHubIcon from "@material-ui/icons/GitHub";
import Tooltip from "@material-ui/core/Tooltip";
import SchoolIcon from "@material-ui/icons/School";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import TagManager from "react-gtm-module";

const gtmArgs = {
  contact: (label) => {
    return {
      gtmId: "GTM-MTR6VF7",
      events: {
        event: "vic.event",
        event_data: {
          category: "card contact button",
          action: "click",
          value: label,
        },
      },
    };
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cardTitle: {
    display: "inline-block!important",
    verticalAlign: "bottom!important",
  },
  cover: {
    width: 400,
  },
  gridItem: {
    alignSelf: "center",
  },
  icon: {
    marginRight: "10px",
    fontSize: "1.5em",
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

const getAge = (birthday) => {
  // birthday is a date
  var ageDifMs = Date.now() - birthday.getTime();
  var ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};

const ProfileCard = () => {
  const classes = useStyles();
  // const theme = useTheme();

  return (
    <Card className={classes.root}>
      <CardMedia
        className={classes.cover}
        image="/vic.jfif"
        title="Victor Gerbrands"
      />

      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Grid container spacing={0}>
            {/* <Grid item xs={2} className={classes.gridItem}> 
            <AccountCircleIcon className={classes.icon} />
          </Grid> */}
            <Grid item xs={12}>
              <AccountCircleIcon className={classes.icon} />
              <Typography
                component="h5"
                variant="h6"
                className={classes.cardTitle}
              >
                Victor Gerbrands
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LocationCityIcon className={classes.icon} />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.cardTitle}
              >
                Rotterdam
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <CakeIcon className={classes.icon} />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.cardTitle}
              >
                {getAge(new Date("01-04-1990"))} jaar
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SchoolIcon className={classes.icon} />
              <Typography
                variant="h6"
                color="textSecondary"
                className={classes.cardTitle}
              >
                WdKA (BBA 2015)
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <div className={classes.controls}>
          <Tooltip title="App me">
            <IconButton
              href="https://wa.me/+31624169563"
              target="_blank"
              aria-label="whatsapp"
              onClick={() => TagManager.initialize(gtmArgs.contact("whatsapp"))}
            >
              <WhatsAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="E-mail">
            <IconButton
              href="mailto:hallo@victorgerbrands.nl"
              target="_blank"
              aria-label="email"
              onClick={() => TagManager.initialize(gtmArgs.contact("email"))}
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="LinkedIn">
            <IconButton
              href="https://www.linkedin.com/in/victorgerbrands/"
              target="_blank"
              aria-label="linkedin"
              onClick={() => TagManager.initialize(gtmArgs.contact("linkedin"))}
            >
              <LinkedInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="GitHub">
            <IconButton
              href="https://github.com/VariableVic/"
              target="_blank"
              aria-label="github"
              onClick={() => TagManager.initialize(gtmArgs.contact("github"))}
            >
              <GitHubIcon className={classes.github} />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default ProfileCard;
