import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import TagManager from "react-gtm-module";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepper: {
    paddingLeft: "0px",
    paddingTop: theme.spacing(4),
  },
  pointer: {
    cursor: "pointer!important",
  },
  chip: {
    marginRight: "5px",
    marginBottom: "5px",
  },
  regular: {
    fontWeight: "400",
  },
  vmiddle: {
    verticalAlign: "middle",
  },
}));

const getSteps = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  return [
    <>
      Growth Engineer <span className={classes.regular}>bij</span> Medusa{" "}
      <span className={classes.regular}>(apr. 2023 - heden)</span>
    </>,
    <>
      Growth Engineer <span className={classes.regular}>bij</span> Goboony{" "}
      <span className={classes.regular}>(jul. 2021 - apr. 2023)</span>
    </>,
    <>
      Growth Engineer <span className={classes.regular}>bij</span> Zoover{" "}
      <span className={classes.regular}>(aug. 2020 - jul. 2021)</span>
    </>,
    <>
      Growth Hacker <span className={classes.regular}>bij</span> Vakanties.nl{" "}
      <span className={classes.regular}>(apr. 2018 - aug. 2020)</span>
    </>,
    <>
      Online Marketing Specialist <span className={classes.regular}>bij</span>{" "}
      2Bfound <span className={classes.regular}>(mrt. 2015 – apr. 2018)</span>
    </>,
    <>
      Jr. Online Marketeer <span className={classes.regular}>bij</span> 2Bfound{" "}
      <span className={classes.regular}>(sep. 2013 – mrt. 2015)</span>
    </>,
  ];
};

const getStepContent = (step) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();
  switch (step) {
    case 5:
      return (
        <>
          <Chip
            label="Online Advertising"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Web Analytics"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="SEO" variant="outlined" className={classes.chip} />
          <p>
            Tijdens mijn studie ging ik aan de slag bij 2Bfound, een
            online-marketingbureau in Dordrecht. Ik maakte en beheerde Google
            Ads campagnes, richtte ik Google Analytics accounts in en
            rapporteerde ik aan m'n eigen klanten.
          </p>
          <p>
            Hier leerde ik datagedreven werken - de juiste data verzamelen,
            knelpunten vinden en verbeteringen testen. Samen met eigenaar
            Wilfred Stomp heb ik de Advertising tak van het bedrijf verder
            vormgegeven en laten groeien.
          </p>
        </>
      );
    case 4:
      return (
        <>
          <Chip
            label="Online Advertising"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Web Analytics"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Google Tag Manager"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Experimenten"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Marketing Automation"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="Front-end" variant="outlined" className={classes.chip} />
          <p>
            Na m'n studie ging ik fulltime aan de slag bij 2Bfound. Ik werd
            verantwoordelijk voor alle Advertising en E-commerce klanten. Data
            was altijd het uitgangspunt. Met Google Tag Manager en JavaScript
            leerde ik complexe Analytics implementaties te doen voor allerlei
            verschillende bedrijven.
          </p>
          <p>
            Mijn tofste projecten bij 2Bfound:
            <ul>
              <li>
                Ruim 40 klanten online laten groeien door slim adverteren en
                sterk datagebruik
              </li>
              <li>
                Het hele rapportageproces geautomatiseerd, zodat ik tijd
                overhielt voor leukere dingen
              </li>
              <li>
                Met het team intern een succesvolle webshop opgezet en flink
                laten groeien (club-champagne.nl)
              </li>
              <li>
                Klanten en collega's getraind in Facebook Ads, Google Ads,
                Analytics en Tag Manager
              </li>
            </ul>
          </p>
        </>
      );
    case 3:
      return (
        <>
          <Chip
            label="Experimenten"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="DataLayer" variant="outlined" className={classes.chip} />
          <Chip
            label="Google Tag Manager"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Web Analytics"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Marketing Automation"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="Front-end" variant="outlined" className={classes.chip} />
          <p>
            Kort na de livegang van Vakanties.nl sloot ik me aan bij het team.
            Hier heb ik me ruim twee jaar gefocust op experimenten, data
            analytics, marketing automation en personalisatie. Ik leerde werken
            met de nieuwste tech- en marketingstack.
          </p>
          <p>
            Mijn tofste projecten bij Vakanties.nl:
            <ul>
              <li>
                100+ experimenten gerund en met het team 10x groei gerealiseerd
                in het tweede jaar
              </li>
              <li>
                Alle e-mailflows geautomatiseerd en gepersonaliseerd in
                Salesforce Marketing Cloud
              </li>
              <li>
                Meegebouwd aan custom clickstream collector (GTM, Divolte,
                Google Cloud)
              </li>
              <li>DataLayer en tagging strategie ontwikkeld</li>
              <li>
                Strategie + framework ontwikkeld voor website personalisatie
              </li>
              <li>
                Hyperpersoonlijke abandoned cart campagne ontwikkeld -{" "}
                <a href="https://programmaticawards.com/shortlist-2019/">
                  genomineerd voor IAB Programmatic award 2019
                </a>
              </li>
            </ul>
          </p>
        </>
      );
    case 2:
      return (
        <>
          <Chip
            label="Experimenten"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="Front-end" variant="outlined" className={classes.chip} />
          <Chip label="Back-end" variant="outlined" className={classes.chip} />
          <Chip label="DataLayer" variant="outlined" className={classes.chip} />
          <Chip
            label="Marketing Automation"
            variant="outlined"
            className={classes.chip}
          />
          <p>
            Halverwege 2020 neemt Vakanties.nl Zoover over en gaan we verder
            onder de 'nieuwe' naam. Inmiddels heeft covid een flinke luwte
            veroorzaakt in de reisbranche. Ik besluit deze periode te gebruiken
            om mijn technische skills verder de ontwikkelen. Als Growth Engineer
            wil ik me verder ontwikkelen als full-stack developer die focust op
            groei, experimenten en data. Intern heb ik samen met senior
            developers een ontwikkelplan gemaakt, en momenteel stort ik me in
            React, Next.js, Python, Google Cloud Platform en Git.
          </p>
          <p>
            Huidge projecten bij Zoover:
            <ul>
              <li>
                Front-end development van het nieuwe gecombineerde platform
              </li>
              <li>Websitepersonalisatie</li>
              <li>
                Clickstream collector inrichten op Zoover (GTM, Divolte, Google
                Cloud)
              </li>
              <li>DataLayer en tagging strategie ontwikkelen</li>
              <li>Integratie Salesforce Marketing Cloud</li>
            </ul>
          </p>
        </>
      );
    case 1:
      return (
        <>
          <Chip
            label="Experimenten"
            variant="outlined"
            className={classes.chip}
          />
          <Chip label="Front-end" variant="outlined" className={classes.chip} />
          <Chip
            label="Growth Strategie"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Data Strategie"
            variant="outlined"
            className={classes.chip}
          />
          <p>
            Bij Goboony heb ik geholpen met het opzetten van het growth team en
            processen, en de eerste 70 experimenten gedraaid.
          </p>
          <ul>
            <li>Verzamelen en rangschikken van growth ideeën</li>
            <li>Opzetten van gestructureerd growth proces</li>
            <li>
              Ontwikkelen, meten en analyseren van 70+ experimenten in JS en
              Ruby on Rails
            </li>
            <li>Implementeren van Amplitude en GCP Retail API</li>
            <li>Automatiseren van interne workflows</li>
          </ul>
        </>
      );
    case 0:
      return (
        <>
          <Chip label="Next.js" variant="outlined" className={classes.chip} />
          <Chip
            label="Typescript"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Growth Proces"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Product Analytics"
            variant="outlined"
            className={classes.chip}
          />
          <Chip
            label="Experimenten"
            variant="outlined"
            className={classes.chip}
          />
          <p>
            Medusa is een open source commerce SDK. We maken de bouwstenen die
            je nodig hebt om geweldige e-commerce websites te bouwen of
            commerce-functies toe te voegen aan elk product.
          </p>
          <p>
            Met onze bouwstenen hoef je niet vanaf nul de basislogica voor
            e-commerce op te bouwen. Je kunt je concentreren op het maatwerk dat
            het verschil maakt. Alle modules zijn gratis en open source, dus jij
            houdt altijd de controle over je eigen commerce-opzet en data.
          </p>
        </>
      );
    default:
      return "Unknown step";
  }
};

const ExperienceStepper = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    TagManager.initialize(gtmArgs.step(activeStep + 1));
    if (activeStep + 1 === steps.length) {
      TagManager.initialize(gtmArgs.finish());
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    TagManager.initialize(gtmArgs.step(activeStep - 1));
  };

  const handleClick = (index) => {
    setActiveStep(index);
    TagManager.initialize(gtmArgs.step(index));
  };

  const handleReset = () => {
    setActiveStep(0);
    TagManager.initialize(gtmArgs.step(0));
  };

  const gtmArgs = {
    step: (index) => {
      return {
        gtmId: "GTM-MTR6VF7",
        events: {
          event: "vic.event",
          event_data: {
            category: "step",
            action: "view",
            value: index,
          },
        },
      };
    },
    finish: () => {
      return {
        gtmId: "GTM-MTR6VF7",
        events: {
          event: "vic.event",
          event_data: {
            category: "stepper finished",
            action: "view",
          },
        },
      };
    },
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3" element="h3">
        Werkervaring
      </Typography>
      <Typography variant="subtitle1">
        <ArrowDownwardIcon className={classes.vmiddle} />
        Dit heb ik allemaal gedaan.
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={classes.stepper}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel
              className={activeStep !== index ? classes.pointer : null}
              onClick={(e) => handleClick(index)}
            >
              <Typography variant="h6">{label}</Typography>
            </StepLabel>
            <StepContent>
              <Typography variant="span">{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Vorige
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                  >
                    {activeStep === steps.length - 1 ? "Einde" : "Volgende"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper
          square
          elevation={0}
          className={`${classes.resetContainer} ${classes.stepper}`}
        >
          <Typography>
            Je hebt het gehaald! Dat was alles tot nu toe.
          </Typography>
          <Button onClick={handleReset} className={classes.button}>
            Opnieuw
          </Button>
        </Paper>
      )}
    </div>
  );
};

export default ExperienceStepper;
