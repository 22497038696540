import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  starRating: {},
  star: {
    fontSize: "1em",
  },
});

const SimpleCard = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {props.category}
        </Typography>
        <Typography variant="h5" component="h2">
          {props.title}
        </Typography>
        <p>{props.intro}</p>
        <ul>
          {props.list ? props.list.map((li, i) => <li key={i}>{li}</li>) : null}
        </ul>
      </CardContent>
    </Card>
  );
};

export default SimpleCard;
