import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import TerrainIcon from "@material-ui/icons/Terrain";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import SimpleCard from "./SimpleCard";
import ProfileCard from "./ProfileCard";
import ExperienceStepper from "./ExperienceStepper";
import cardsContent from "./cardsContent";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Tooltip from "@material-ui/core/Tooltip";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import IconButton from "@material-ui/core/IconButton";
import StarIcon from "@material-ui/icons/Star";
import TagManager from "react-gtm-module";
import qrcode from "qrcode-terminal";

const business = new URLSearchParams(window.location.search).get("b");
const role = new URLSearchParams(window.location.search).get("r");
const color = new URLSearchParams(window.location.search).get("c");

const gtmArgs = {
  init: {
    gtmId: "GTM-MTR6VF7",
    events: {
      event: "gtm.js",
    },
  },
  button: (e) => {
    return {
      gtmId: "GTM-MTR6VF7",
      events: {
        event: "vic.event",
        event_data: {
          category: "button",
          action: "click",
          value: e.target.innerText,
        },
      },
    };
  },
  contact: (label) => {
    return {
      gtmId: "GTM-MTR6VF7",
      events: {
        event: "vic.event",
        event_data: {
          category: "footer contact button",
          action: "click",
          value: label,
        },
      },
    };
  },
};

TagManager.initialize(gtmArgs.init);

const handleClick = {
  button: (e) => {
    let button = gtmArgs.button(e);
    TagManager.initialize(button);
    window.analytics.track("click", button.events.event_data);
  },
  contact: (e) => {
    let contact = gtmArgs.contact(e);
    TagManager.initialize(contact);
    window.analytics.track("click", contact.events.event_data);
  },
};

const Copyright = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="/">
        Victor Gerbrands
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: color ? "#" + color : "#009ade", //'#006137',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: "#19FA98",
      main: "#00AD62",
      // dark: will be calculated from palette.secondary.main,
      contrastText: "#ffcc00",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    text: {
      tertiary: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
    fontSize: "2.5em",
  },
  subtitle: {
    display: "block",
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGridContainer: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
  },
  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  cardMedia: {
    paddingTop: "56.25%", // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  experienceWrap: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 4, 6),
    marginBottom: theme.spacing(8),
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  skinny: {
    fontWeight: 300,
  },
  fat: {
    fontWeight: 700,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    marginRight: "auto",
    width: "150px",
    textAlign: "center",
  },
  star: {
    fontSize: "1em",
    marginRight: "2px",
  },
}));

qrcode.generate("https://wa.me/+31624169563", function (qrcode) {
  console.log(
    "%c Hallo! Nu je toch zo ver bent gekomen, kun je me net zo goed een berichtje sturen:",
    `background: ${theme.palette.primary.main}; color: white; font-weight: bold; font-size: 20px; padding: 5px;`
  );
  console.log(qrcode);
});

const App = () => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <CssBaseline />
        <AppBar position="relative">
          <Toolbar>
            <TerrainIcon className={classes.icon} />
            <Typography
              component="h1"
              variant="h6"
              color={theme.palette.text.tertiary}
              noWrap
            >
              Victor Gerbrands:&nbsp;
              <span className={classes.skinny}>
                {role
                  ? role === "wa"
                    ? "Technisch Web Analist | Growth Engineer"
                    : "Growth Engineer | Technisch Web Analist"
                  : "Growth Engineer | Technisch Web Analist"}
              </span>
            </Typography>
          </Toolbar>
        </AppBar>
        <main>
          {/* Hero unit */}
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography
                component="h2"
                variant="h2"
                align="center"
                color="textPrimary"
                gutterBottom
              >
                {business ? `Hey ${business}` : "Hey"}, ik ben Victor.
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                Ik help digitale producten verder met duurzame, datagedreven
                groei.
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                Dat doe ik met robuuste analytics en snelle, datagedreven
                experimenten.
              </Typography>
              <Typography
                variant="h5"
                align="center"
                color="textSecondary"
                paragraph
              >
                Mijn kernwaarden zijn{" "}
                <span className={classes.fat}>
                  vrijheid, onafhankelijkheid, nieuwsgierigheid, authenticiteit
                </span>{" "}
                en <span className={classes.fat}>eerlijkheid</span>.
              </Typography>
              <ProfileCard />
              <div className={classes.heroButtons}>
                <Grid container spacing={2} justify="center">
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      href="#skills"
                      onClick={(e) => handleClick.button(e)}
                    >
                      Bekijk skills
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      href="#werkervaring"
                      onClick={(e) => handleClick.button(e)}
                    >
                      Bekijk werkervaring
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </div>
          <Container
            className={classes.cardGridContainer}
            maxWidth="md"
            id="skills"
          >
            {/* End hero unit */}
            <Typography variant="h3" element="h3">
              Skills
            </Typography>
            <Typography variant="subtitle1">
              {/* <StarIcon /> */}
              Hier kan ik je mee helpen.
            </Typography>
            <Grid container spacing={4} className={classes.cardGrid}>
              {cardsContent(role).map((card, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <SimpleCard
                    category={card.category}
                    title={card.title}
                    stars={card.stars}
                    intro={card.intro}
                    list={card.list}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
          <Container className={classes.experienceWrap} maxWidth="md" id="cta">
            <Typography variant="h6" align="center" gutterBottom>
              Dus, zit je vol met ideeën die je uit wil testen? Of wil je je
              dataverzameling op orde krijgen?
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              component="p"
            >
              Neem contact met me op:
            </Typography>
            <div className={classes.controls}>
              <Tooltip title="App me">
                <IconButton
                  href="https://wa.me/+31624169563"
                  target="_blank"
                  aria-label="whatsapp"
                  onClick={() => handleClick.contact("whatsapp")}
                >
                  <WhatsAppIcon aria-label="whatsapp" />
                </IconButton>
              </Tooltip>
              <Tooltip title="E-mail">
                <IconButton
                  href="mailto:hallo@victorgerbrands.nl"
                  target="_blank"
                  aria-label="email"
                  onClick={() => handleClick.contact("email")}
                >
                  <MailOutlineIcon aria-label="email" />
                </IconButton>
              </Tooltip>
              <Tooltip title="LinkedIn">
                <IconButton
                  href="https://www.linkedin.com/in/victorgerbrands/"
                  target="_blank"
                  aria-label="linkedin"
                  onClick={() => handleClick.contact("linkedin")}
                >
                  <LinkedInIcon aria-label="linkedin" />
                </IconButton>
              </Tooltip>
            </div>
          </Container>
          <Container
            className={classes.experienceWrap}
            maxWidth="md"
            id="werkervaring"
          >
            <ExperienceStepper />
          </Container>
        </main>
        {/* Footer */}
        <footer className={classes.footer}>
          <Copyright />
        </footer>
        {/* End footer */}
      </React.Fragment>
    </ThemeProvider>
  );
};

export default App;
